/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import hordeImg from "../images/horde1opac1.png"
import Header from './header'
import './layout.css'

const theme = createMuiTheme({
  palette: {
    type: "dark", 
    text: {
      primary: "#fff"
    }
  },
  typography: {
    fontFamily: ['"Black Ops One", cursive', 'Roboto'],
},
overrides: {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundImage: 'url(' + hordeImg + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize:'100vh',
              backgroundPosition:'center top'
      },
    },
  },
  MuiInputBase: {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  },
},
});

const Layout = ({ pageTitle, children }) => {
  return (
    // <StaticQuery
    //   query={graphql`
    //     query SiteTitleQuery {
    //       site {
    //         siteMetadata {
    //           title
    //         }
    //       }
    //     }
    //   `}
      // render={data => (
      <ThemeProvider theme={theme}>
        <div style={{ 
              }}>
          <Header theme={theme} siteTitle={pageTitle} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0.5rem 0.5rem 0.5rem 0.5rem`,
              paddingTop: 70,
            }}
          >

            <main>{children}</main>
            <footer style={{ paddingTop: 10 }}>
              
            </footer>
          </div>
        </div>
        </ThemeProvider>
      )}
    // />
//   )
// }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
