import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import clsx from 'clsx'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react';
import MapIcon from '@material-ui/icons/Map';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Box, Button } from '@material-ui/core'
import LogInOrSignUp from '../components/SignUp'
import LoginController from '../components/SignUp'
import Contact from '../components/Contact'
import { Chat, ContactSupport, ShoppingCart } from '@material-ui/icons'
import GamepadIcon from '@material-ui/icons/Gamepad';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = '40%'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'black',
    // backgroundImage: 'url(' + bulletImg + ')',
    //           backgroundRepeat: 'no-repeat',
    //           backgroundSize:'contain',
    //           backgroundPosition:'left'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'darkred'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  whiteText: {
    color: "white",
    paddingTop: '0.1rem',
    paddingBottom: '0.5rem'
  },
  menuButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(4),
  },
  menuTxt: {
    fontSize: '12px'
  },
  titleTxt: {
    borderLeft: '1px solid white'
  },
  compactIcon:{
    minWidth:'3rem'
  }
}))

const CompactListItem = ({icon, children}) => {
  const classes = useStyles()

  return (
    <ListItem button className={classes.whiteText} >
      <ListItemIcon className={classes.compactIcon} >
        {icon}
      </ListItemIcon>
      {children}
    </ListItem>
  )
}

const Header = ({ theme, siteTitle }) => {
  const classes = useStyles()

  // const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }


  const checkLoggedIn = () => {
    let lsUser
    if (typeof window !== `undefined`) lsUser = window.localStorage.getItem('userDetails')
    try {
      const userDetails = JSON.parse(lsUser)
      if (userDetails.user_name && userDetails.password) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    } catch (e) {
      setIsLoggedIn(false)
    }
  }

  useEffect(() => {
    checkLoggedIn()
  }, [])


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Box display="flex" flexDirection="column">
            <IconButton
              onClick={handleDrawerOpen}
              edge="start"
              classes={{ label: classes.menuButton }}
              className={clsx(open && classes.hide)}
            >

              <MenuIcon />
              <Typography className={classes.menuTxt}>Menu</Typography>

            </IconButton>

          </Box>
          <Box>

          </Box>
          <Typography variant="h6" style={{ flex: 1 }}>
            {siteTitle}
          </Typography>
          <Box pr={2}>
            <Link to="/buy">
              <Button variant="contained" color="secondary">Buy Server</Button>
            </Link>
          </Box>
          <Box >
            <LogInOrSignUp setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        onClose={handleDrawerClose}
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}

          </IconButton>
        </div>
        <Divider />
        <List>
          <Box >
            <CompactListItem icon={<WhatshotIcon />}>
              <LoginController isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            </CompactListItem>
          </Box>
          <Link to="/buy">
              <CompactListItem icon={<ShoppingCart />}>
                <ListItemText >Buy Server</ListItemText>
              </CompactListItem>
            </Link>
          <Link to="/">
            <CompactListItem icon={<HomeIcon />}>
              <ListItemText >Server List</ListItemText>
            </CompactListItem>
          </Link>
          <Link to="/rcon">
            <CompactListItem icon={<SettingsRemoteIcon />}>
              <ListItemText>RCON</ListItemText>
            </CompactListItem>
          </Link>
          <Link to="/mapsList">
            <CompactListItem icon={<MapIcon />}>
              <ListItemText >Maps</ListItemText>
              </CompactListItem>
          </Link>
          <Link to="/modList">
            <CompactListItem icon={<SettingsIcon />}>
              <ListItemText >Mods</ListItemText>
              </CompactListItem>
          </Link>
          <Link to="/gameModeList">
            <CompactListItem icon={<GamepadIcon />}>
              <ListItemText >Game Modes</ListItemText>
              </CompactListItem>
          </Link>
          <Box>
            <a href="https://discord.gg/ajyWn6YWWm" target="_blank">
              <CompactListItem icon={<Chat />}>
                <ListItemText >Join Horde Discord</ListItemText>
              </CompactListItem>
            </a>
          </Box>
            <Link to="/league">
              <CompactListItem icon={<EmojiEventsIcon />}>
                <ListItemText >Join Pavlov Champions League</ListItemText>
              </CompactListItem>
            </Link>
              <CompactListItem icon={<ContactSupport/>}>
                <Contact isRaw/>
              </CompactListItem>
        </List>
      </Drawer>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
