import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, List, ListItem, makeStyles, Modal, TextField, Typography, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import * as api from '../api/calls'
import { Link } from 'gatsby'
import * as myRegex from '../data/regex.json'

const useStyles = makeStyles({
    promptTxt: {
        fontFamily: 'Roboto',
        fontSize: '0.75em'
    },
    spaced: {
        justifyContent: "space-evenly"
    },
    alert: {
        fontFamily: 'Roboto',
        fontSize: '0.8rem',
        color: 'red',
        paddingLeft: '1rem'
    },
    success: {
        fontFamily: 'Roboto',
        fontSize: '0.8rem',
        color: 'white',
        paddingLeft: '1rem'
    }
})



function SignUp({ isLoading, alert, isOpen, handleUser, handleClose, handleSubmit, handleSwitch }) {

    const classes = useStyles();
    const noWhiteSpaceR = myRegex.whitespace
    const isEmailR = myRegex.email

    const defaultDataFields = [
        {
            id: "email",
            label: "Email",
            type: "email",
            errorRegex: isEmailR,
            errorTxt: "Not valid email"
        }, {
            id: "discord",
            label: "Discord Name",
            type: "text",
            errorRegex: noWhiteSpaceR,
            errorTxt: "Please remove whitespace"
        }, {
            id: "username",
            label: "Username",
            type: "text",
            errorRegex: noWhiteSpaceR,
            errorTxt: "Please remove whitespace"
        }, {
            id: "password",
            label: "Password",
            type: "password",
            errorRegex: noWhiteSpaceR,
            errorTxt: "Please remove whitespace"
        }]

    const [dataFields, setDataFields] = useState(defaultDataFields)
    const isErrorsArr = dataFields.map(d => d.isError)
    const isErrorGlobal = isErrorsArr.includes(true)
    // console.log(isErrorsArr, isErrorGlobal)

    const handleChange = (e) => {
        const { value, id } = e.target
        const dataFieldi = dataFields.findIndex(d => d.id == id)
        const dataFieldRegex = dataFields[dataFieldi].errorRegex
        if (dataFieldRegex) {
            const thisRegex = new RegExp(dataFieldRegex)
            const isError = !thisRegex.test(value)
            const newDataFields = dataFields.map((d, i) => i == dataFieldi ? { ...d, isError } : d)
            setDataFields(newDataFields)

        }

        handleUser(e)

    }

    return (
        <Box>
            <Dialog
                open={isOpen}
                onClose={handleClose}>
                <DialogTitle>Sign Up for Horde Account</DialogTitle>


                <Box>
                    <List>
                        {dataFields.map((field, i) =>
                        (
                            <ListItem key={field.id + i}><TextField
                                id={field.id}
                                label={(field.isError && field.errorTxt) || field.label}
                                type={field.type}
                                onChange={handleChange}
                                error={field.isError}
                            />
                            </ListItem>
                        )
                        )}
                    </List>
                    {alert && <Typography className={classes.alert}>{alert}</Typography>}
                    <DialogActions className={classes.spaced}>
                        <Button onClick={handleClose}>Cancel</Button>
                        {isLoading ? <CircularProgress /> :
                            isErrorGlobal ? <Typography className={classes.alert}>Please Fix Errors Above</Typography> :
                                <Button variant="contained" color="secondary" onClick={handleSubmit}>Register</Button>
                        }
                    </DialogActions>
                    <DialogActions className={classes.spaced}>
                        <Button className={classes.promptTxt} onClick={handleSwitch}>Already Registered? <br /> Log In</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
}

function LogIn({ handleForget, isLoading, alert, isOpen, handleUser, handleClose, handleSubmit, handleSwitch }) {
    const classes = useStyles();

    const [isChecked, setChecked] = useState(true)

    const handleCheck = () => {
        setChecked(!isChecked)
    }

    const dataFields = [
        {
            id: "username",
            label: "Username",
            type: "text"
        }, {
            id: "password",
            label: "Password",
            type: "password"
        }]

    return (
        <Box>
            <Dialog
                open={isOpen}
                onClose={handleClose}>
                <DialogTitle>Log In to Your Horde Account</DialogTitle>
                <Box>
                    <List>
                        {dataFields.map((field, i) => (
                            <ListItem key={field.id + i}><TextField
                                id={field.id}
                                label={field.label}
                                type={field.type}
                                onChange={handleUser}
                            />
                            </ListItem>
                        ))}
                        <Box display="flex" alignItems="center">
                            <Checkbox onChange={handleCheck} checked={isChecked} size="small" /> <Typography className={classes.promptTxt}>Remember Me</Typography>
                        </Box>
                    </List>
                    {alert && <Typography className={classes.alert}>{alert}</Typography>}
                    <DialogActions className={classes.spaced}>


                        <Button onClick={handleClose}>Cancel</Button>
                        {isLoading ? <CircularProgress /> :
                            <Button variant="contained" color="secondary" onClick={handleSubmit}>Log In</Button>
                        }
                    </DialogActions>
                    <DialogActions className={classes.spaced}>
                        <Button className={classes.promptTxt} onClick={handleSwitch}>Not Yet Registered? <br /> Sign Up</Button>

                        <Button className={classes.promptTxt} onClick={handleForget}>Forgot Password?</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
}

function LogInOrSignUp({ autoOpen, setIsLoggedIn }) {

    const [login, setLogIn] = useState(true)

    const [userDetails, setUser] = useState({})
    const [open, setOpen] = useState(!!autoOpen)

    const [forgotten, setForgotten] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setForgotten(false)
    }
    const handleUser = (event) => {
        const userDataChange = { ...userDetails, [event.target.id]: event.target.value }
        setUser(userDataChange)
    }

    const handleSignUpSubmit = async () => {
        try {
            const { username, password, discord, email } = userDetails
            if (username && password && discord && email) {
                setLoading(true)
                const res = await api.createUser(username, password, {}, { email, discord })
                console.log('Signed up:', res)
                if (typeof window !== `undefined`) window.localStorage.setItem('userDetails', JSON.stringify(res.data.user))
                setLoading(false)
                setIsLoggedIn(true)
                setOpen(false)
                if (window.location.pathname == 'horde') window.location.reload()
            } else {
                setError('Please fill all fields.')
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setError(e.response && 'Error: ' + e.response.data.message + '. Please try again.')
            setLoading(false)
        }

    }

    const handleLogInSubmit = async () => {

        try {
            setLoading(true)
            let myUserDetails = await api.getUser(userDetails.username, userDetails.password)
            // console.log('DB:',myUserDetails)
            const serverList = await api.getAllServers()
            let myServers = myUserDetails.user_perms.servers && myUserDetails.user_perms.servers.map(s => {
                const liveServer = serverList.find(x => x.ip == s.ip) || s
                const joinedServer = { ...s, ...liveServer }
                return joinedServer
            })
            myServers = myServers.filter(s => s.active)
            myUserDetails.user_perms.servers = myServers
            myUserDetails = { ...userDetails, ...myUserDetails }
            setOpen(false)
            console.log('Logged In')
            window.localStorage.setItem('userDetails', JSON.stringify(myUserDetails))
            setIsLoggedIn(true)
        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e.message))
            setLoading(false)
        }
    }

    const handleSwitch = () => {
        setLogIn(!login)
    }

    const handleForget = () => {
        setForgotten(true)
    }

    const handleForgetSubmit = () =>{
        setLoading(true)
        console.log('Forget Submit', userDetails.username, userDetails.email)
        api.resetUserPass(userDetails.username, userDetails.email)
            .then(res=>{
                setLoading(false)
                setError('')
                setSuccess(`Password reset email sent to ${userDetails.email}`)
            })
            .catch(e=>{
                console.log(e)
                setLoading(false)
                setError('Incorrect Details')
            })
    }


    const forgetProps = { isOpen:open, handleClose, isLoading, handleSubmit:handleForgetSubmit, handleUser, error, success }

    return (
        <Box>
            {autoOpen ? '' :
                <Button variant="outlined" onClick={handleOpen}>Sign Up / Log In</Button>}
            {open && forgotten ?
                <ForgetPass props={forgetProps}/> :
                open && (login) ?
                    <SignUp isLoading={isLoading} alert={error} isOpen={open} handleClose={handleClose} handleUser={handleUser} handleSubmit={handleSignUpSubmit} handleSwitch={handleSwitch} />
                    : <LogIn handleForget={handleForget} isLoading={isLoading} alert={error} isOpen={open} handleClose={handleClose} handleUser={handleUser} handleSubmit={handleLogInSubmit} handleSwitch={handleSwitch} />
            }
        </Box>
    )
}

function ForgetPass({props}) {

    // console.log(props)
    const classes = useStyles()

    const { isOpen, handleClose, isLoading, handleSubmit, handleUser, error, success } = props

    const dataFields = [
        {
            id: "username",
            label: "Username",
            type: "text"
        }, {
            id: "email",
            label: "Email",
            type: "email"
        }]

    return (
        <Box>
            <Dialog
                open={isOpen}
                onClose={handleClose}>
                <DialogTitle>Enter Username and Email to Reset Password</DialogTitle>
                <Box>
                    <List>
                        {dataFields.map((field, i) => (
                            <ListItem key={field.id + i}><TextField
                                id={field.id}
                                label={field.label}
                                type={field.type}
                                onChange={handleUser}
                            />
                            </ListItem>
                        ))}
                    </List>
                    
                    <DialogActions className={classes.spaced}>
                        
                            <Button onClick={handleClose}>Cancel</Button>
                            {isLoading ? <CircularProgress /> :
                                <Button variant="contained" color="secondary" onClick={handleSubmit}>Reset Password</Button>
                            }
                        
                    </DialogActions>
                    <Box pb={1} className={classes.alert}>
                        {error}
                    </Box>
                    <Box pb={1} className={classes.success}>
                        {success}
                    </Box>

                </Box>
            </Dialog>
        </Box>

    )
}

function MyAccountButton() {
    return (
        <Link to='/horde' state={{ isLoggedIn: true }}><Button variant="outlined">My Account</Button></Link>
    )
}

export default function LoginController({ isLoggedIn, setIsLoggedIn }) {

    return (
        <Box>
            {isLoggedIn ? <MyAccountButton />
                : <LogInOrSignUp setIsLoggedIn={setIsLoggedIn} />
            }
        </Box>
    )

}

export { LoginController, LogInOrSignUp }