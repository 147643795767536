import { Box, Button, Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import WhatshotIcon from '@material-ui/icons/Whatshot'

const useStyles = makeStyles({
    contactList: {
        color: 'white',
        fontSize: '0.75em'
    }
})


export default function ContactUsButton({isRaw}) {
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (


        <Box>
            {isRaw ? 
                <Box >
                    <Button variant="outlined" onClick={handleOpen}>Contact Us</Button>
                    <ContactUsDialog open={open} handleClose={handleClose} />
                </Box>
                :
                <Box>
                    <Button variant="contained" color="secondary" onClick={handleOpen}>Contact Us</Button>
                    <ContactUsDialog open={open} handleClose={handleClose} />
                </Box>
            }
        </Box >

    )


}


function ContactUsDialog({ open, handleClose}) {



    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>{`Contact`}</DialogTitle>
            <ContactUsList />

        </Dialog>
    )
}

function ContactUsList() {

    const classes = useStyles()
    const chingCon = () => `khanchingghisaattgeeemmeelddoottcom`.replace('aatt','@').replace('ddoottcom','.com').replace('geeemmeel','gmail')

    return (
        <Box>
            <a href="https://discord.gg/ajyWn6YWWm" target="_blank">
                <ListItem button >
                    <ListItemIcon>
                        <WhatshotIcon />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>Join Horde Discord</ListItemText>
                </ListItem>
            </a>

            <a href="https://discord.com/users/353799677071720488" target="_blank">
                <ListItem button >
                    <ListItemIcon>
                        <WhatshotIcon />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>Contact Chingghis on Discord</ListItemText>
                </ListItem>
            </a>

            <a href={`mailto:${chingCon()}`} target="_blank">
                <ListItem button >
                    <ListItemIcon>
                        <WhatshotIcon />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>Contact Chingghis by Email</ListItemText>
                </ListItem>
            </a>

        </Box>
    )
}