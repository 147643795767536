const md5 = require('md5')
const axios = require('axios').default
axios.defaults.timeout = 60000
// axios.defaults.baseURL='https://api.pavlovhorde.com:8003'
axios.defaults.baseURL='https://api2.pavlovhorde.com:8003'

// axios.defaults.baseURL = 'http://localhost:8003'


async function getAllGames() {
  const config = {
    method: 'get',
    url: '/getAllGames'
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let gamesList = response.data
  return gamesList
}

async function getAllGamesWeek() {
  const config = {
    method: 'get',
    url: '/getAllGamesWeek'
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let gamesList = response.data
  return gamesList
}

async function getAllGamesServers(serverIPArr) {
  const config = {
    method: 'post',
    url: '/getAllGamesServers',
    data:{serverIPArr}
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let gamesList = response.data
  return gamesList
}

async function getSettings(pwd, server) {

  const config = {
    method: 'post',
    url: `/getSettingsDB`,
    data: { pass: pwd, serverip: server }
  }

  const response = await axios(config)
  let data = response.data
  if (!data){
    const config2 = {
      method: 'post',
      url: `/getSettings`,
      data: { pass: pwd, serverip: server }
    }
  
    const response2 = await axios(config2)
    data = response2.data
  }

  return data

}

async function getWebhook(pwd, server) {

  const config = {
    method: 'post',
    url: `/getWebhook`,
    data: { pass: pwd, serverip: server }
  }

  const response = await axios(config)
  return response.data

}

async function setSettings(pwd, server, settings) {

  const config = {
    method: 'post',
    url: `/setSettings`,
    data: { gameini: settings, pass: pwd, serverip: server }
  }

  const response = await axios(config)
  return response

}

async function setWebhook(pwd, server, webhook) {

  const config = {
    method: 'post',
    url: `/setWebhook`,
    data: { webhook, pass: pwd, serverip: server }
  }

  const response = await axios(config)
  return response

}

async function getUser(username, password) {
  const config = {
    method: 'post',
    url: '/getUser',
    data: {
      user: username,
      pass: password
    }
  }

  const response = await axios(config)
  return response.data
}

async function getServers() {
  const config = {
    method: 'get',
    url: '/getServers'
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let servers = response.data.servers
  // servers = servers.sort((a, b) => b.slots - a.slots)
  return servers
}

async function getServersRC() {
  const config = {
    method: 'get',
    url: '/getServersRC'
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let servers = response.data.servers
  // servers = servers.sort((a, b) => b.slots - a.slots)
  return servers
}

async function getAllServers() {
  const servers = await getServers()
  const serversRC = await getServersRC()
  const allServers = [...servers, ...serversRC]
  return allServers
}

async function getMapsJSON(){
  const config = {
    method: 'get',
    url: '/getMapsJSON'
  }

  const response = await axios(config)
  return response.data

}

async function getMapsRanked(UEversion){
  const config = {
    method: 'get',
    url: '/getMapsRanked',
    params:{
      version:UEversion
    }
  }

  const response = await axios(config)
  return response.data

}

async function getFavMaps(){
  const config = {
    method: 'get',
    url: '/getFavMaps'
  }

  const response = await axios(config)
  return response.data

}

async function getMaps427(){
  const config = {
    method: 'get',
    url: '/getMaps427'
  }

  const response = await axios(config)
  return response.data

}

async function getHomeMaps(){
  const config = {
    method: 'get',
    url: '/getHomeMaps'
  }

  const response = await axios(config)
  return response.data

}

async function getServersRaw() {


  const config = {
    method: 'post',
    url: 'https://shack-ms.vankrupt.com/servers/v1/list',
    headers: {
      'version': '0.80.60',
      'shack': '1',
      'User-Agent': 'Pavlov VR Game Client',
      'Content-Type': 'text/json',
      'Content-Length': '3',
      'Accept-Encoding': 'deflate, gzip'
    },
    data: "{ }",
  };

  const response = await axios(config)
  const servers = response.data
  return servers
}

async function getServersPC() {

  const config = {
    method: 'get',
    url: '/getServersPC'
  };

  const response = await axios(config).catch(e => console.log(`Axios error: ${e}`))
  let servers = response.data.servers
  servers = servers.sort((a, b) => b.slots - a.slots)
  return servers
}

async function rconConnect(ip, port, password) {
  const rconServer = { ip, port, password }

  const config = {
    method: 'post',
    url: '/getServerInfo',
    data: { rconServer }
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response

}

async function rconSendCommands(ip, port, password, commands, userData) {
  const rconServer = { ip, port, password }
  const rconCommands = [...commands]
// console.log('Executing:',rconCommands)

  const config = {
    method: 'post',
    url: '/processCommands',
    data: { rconServer, rconCommands, userData },
    timeout: 10000
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response
}

async function createCheckoutSession(customerID, priceId, email, metadata) {

  const config = {
    method: 'post',
    url: '/payment/create-checkout-session',
    data: { customerID, priceId, email, metadata },
    timeout: 10000
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response

};

async function redirectToPortal(customerID) {

  const config = {
    method: 'post',
    url: '/payment/create-customer-portal-session',
    data: { customerID },
    timeout: 10000
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response

};

async function getCheckout(sessionId) {

  const config = {
    method: 'post',
    url: '/payment/get-checkout-session',
    data: { sessionId },
    timeout: 10000
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response

};

async function getRegions(productID) {

  const config = {
    method: 'get',
    url: '/vultr/regions',
    params:{productID}
  };

  const response = await axios(config).catch(e => {
    return e
  })
  return response

}

async function createUser(user, pass, perms, detail) {

  const config = {
    method: 'post',
    url: '/createUser',
    data: { user, pass, perms, detail }
  };

  const response = await axios(config)
  return response

}

async function updateUser(user, pass, stripeID){
  const config = {
    method: 'post',
    url: '/updateUserStripeID',
    data: { user, pass, stripeID}
  };

  const response = await axios(config)
  return response
}

async function getProducts(){
  const config = {
    method: 'get',
    url: '/getHordeProducts'
  };

  const response = await axios(config)
  return response.data
}

async function getScoreboards(){
  const config = {
    method: 'get',
    url: '/getScoreboardList'
  };

  const response = await axios(config)
  return response.data
}

async function getTeamsData(){
  const config = {
    method: 'get',
    url: '/getTeamsData'
  };

  const response = await axios(config)
  return response.data
}

async function getRCONLists(){
  const config = {
    method: 'get',
    url: '/getRCONLists'
  };

  const response = await axios(config)
  return response.data
}

async function getServerHistoryByGameMode(){
  const config = {
    method: 'get',
    url: '/getServerHistoryByGameMode'
  };

  const response = await axios(config)
  return response.data
}

async function resetUserPass(user, email){
  const config = {
    method: 'post',
    url: '/resetUserPassword',
    data: {user, email}
  };

  const response = await axios(config)
  return response.data
}

async function changeRCONPassword(user, email, pass, serverip, oldRCONPass, newRCONPass){
  const config = {
    method: 'post',
    url: '/changeRCONPassword',
    data: {user, email, pass, serverip, oldRCONPass, newRCONPass}
  };

  const response = await axios(config)
  return response.data
}


async function getMapsModio(){
  const config = {
    method: 'get',
    url: '/getMapsModio'
  };

  const response = await axios(config)
  return response.data
}

async function getModsModio(){
  const config = {
    method: 'get',
    url: '/getModsModio'
  };

  const response = await axios(config)
  return response.data
}


async function getGameModesModio() {
  const config = {
    method: 'get',
    url: '/getGameModesModio'
  };

  const response = await axios(config)
  return response.data
}

async function getAllGameModes() {
  const modioGameModes = await getGameModesModio()
  const rconLists = await getRCONLists()
  const defaultGameModes = rconLists.defaultGameModes
  const allGameModes = [...modioGameModes, ...defaultGameModes]
  return allGameModes
}


module.exports = {getAllServers, getServersRC, changeRCONPassword, resetUserPass, getServerHistoryByGameMode, getTeamsData, getScoreboards, getHomeMaps, getProducts, getWebhook, setWebhook, updateUser, getCheckout, redirectToPortal, createUser, getRegions, createCheckoutSession, getUser, getSettings, getServers, getServersPC, setSettings, rconConnect, rconSendCommands, getAllGames, getAllGamesWeek, getMapsJSON, getAllGamesServers, getRCONLists, getFavMaps, getMaps427, getMapsRanked, getMapsModio, getModsModio, getGameModesModio, getAllGameModes }
